import '../styles/glob.css';
import { Form, Badge, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from './otherComponents/Autocomplete';
import { translateScreen as ts , translateDate as td} from './dictionary'

  //export const nodeServerSite = 'http://localhost:5050/';
export const nodeServerSite = 'https://api.givenmine.com/';
//TODO: set nmodeserverside to tutoroom. currently localhost
//TODO: change gmbackend.tutoroom.com to the new server (front end and backend)
//TODO: MAke the changes that charbel has done on the server
//TODO: Change the procedure getHotspotStatistics on the server


const dateFormat = require("dateformat");
//#region Constants
export const versionNumber = '1.1.0 Beta'
export const userType = ['Super Admin', 'Admin Account', 'Content Creator', 'Executive Account', 'Audience Manager']
export const sessionStorageVariables = { userData: 'UserData' }
export const errorColor = '#ea0505'
export const hotspotTypes = ['Hyperlink','Email']
export const websiteRoute = {
    home: '/', signIN: "/SignIn", startPage: "/startPage",
    registrationRequest: "/RegistrationRequest", manageUserRegistration:
        "/ManageUserRegistration", userManagement: "/UserManagement", listAllUser: "/ListAllUsers"
}
export const country_list = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte dIvoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kore",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People s Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland",
    "United States Minor Outlying Islands",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela ",
    "Viet Nam",
    "Virgin Islands",
    "Virgin Islands",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands"
];

export const language_list = [
    { code: '', name: 'undefined' },
    { code: 'fr', name: 'French' },
    { code: 'en', name: 'English' },
    { code: 'nl', name: 'Dutch' },
    { code: 'de', name: 'German' }]

export const language_list1 = [
    { code: 'ab', name: 'Abkhazian' },
    { code: 'aa', name: 'Afar' },
    { code: 'af', name: 'Afrikaans' },
    { code: 'ak', name: 'Akan' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'an', name: 'Aragonese' },
    { code: 'hy', name: 'Armenian' },
    { code: 'as', name: 'Assamese' },
    { code: 'av', name: 'Avaric' },
    { code: 'ae', name: 'Avestan' },
    { code: 'ay', name: 'Aymara' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'bm', name: 'Bambara' },
    { code: 'ba', name: 'Bashkir' },
    { code: 'eu', name: 'Basque' },
    { code: 'be', name: 'Belarusian' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bh', name: 'Bihari languages' },
    { code: 'bi', name: 'Bislama' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'br', name: 'Breton' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'my', name: 'Burmese' },
    { code: 'ca', name: 'Catalan, Valencian' },
    { code: 'km', name: 'Central Khmer' },
    { code: 'ch', name: 'Chamorro' },
    { code: 'ce', name: 'Chechen' },
    { code: 'ny', name: 'Chichewa, Chewa, Nyanja' },
    { code: 'zh', name: 'Chinese' },
    { code: 'cu', name: 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
    { code: 'cv', name: 'Chuvash' },
    { code: 'kw', name: 'Cornish' },
    { code: 'co', name: 'Corsican' },
    { code: 'cr', name: 'Cree' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'dv', name: 'Divehi, Dhivehi, Maldivian' },
    { code: 'nl', name: 'Dutch, Flemish' },
    { code: 'dz', name: 'Dzongkha' },
    { code: 'en', name: 'English' },
    { code: 'eo', name: 'Esperanto' },
    { code: 'et', name: 'Estonian' },
    { code: 'ee', name: 'Ewe' },
    { code: 'fo', name: 'Faroese' },
    { code: 'fj', name: 'Fijian' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'ff', name: 'Fulah' },
    { code: 'gd', name: 'Gaelic, Scottish Gaelic' },
    { code: 'gl', name: 'Galician' },
    { code: 'lg', name: 'Ganda' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'ki', name: 'Gikuyu, Kikuyu' },
    { code: 'el', name: 'Greek (Modern)' },
    { code: 'kl', name: 'Greenlandic, Kalaallisut' },
    { code: 'gn', name: 'Guarani' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian, Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'he', name: 'Hebrew' },
    { code: 'hz', name: 'Herero' },
    { code: 'hi', name: 'Hindi' },
    { code: 'ho', name: 'Hiri Motu' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'io', name: 'Ido' },
    { code: 'ig', name: 'Igbo' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ia', name: 'Interlingua (International Auxiliary Language Association)' },
    { code: 'ie', name: 'Interlingue' },
    { code: 'iu', name: 'Inuktitut' },
    { code: 'ik', name: 'Inupiaq' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'jv', name: 'Javanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kr', name: 'Kanuri' },
    { code: 'ks', name: 'Kashmiri' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'kv', name: 'Komi' },
    { code: 'kg', name: 'Kongo' },
    { code: 'ko', name: 'Korean' },
    { code: 'kj', name: 'Kwanyama, Kuanyama' },
    { code: 'ku', name: 'Kurdish' },
    { code: 'ky', name: 'Kyrgyz' },
    { code: 'lo', name: 'Lao' },
    { code: 'la', name: 'Latin' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lb', name: 'Letzeburgesch, Luxembourgish' },
    { code: 'li', name: 'Limburgish, Limburgan, Limburger' },
    { code: 'ln', name: 'Lingala' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lu', name: 'Luba-Katanga' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'gv', name: 'Manx' },
    { code: 'mi', name: 'Maori' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mh', name: 'Marshallese' },
    { code: 'ro', name: 'Moldovan, Moldavian, Romanian' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'na', name: 'Nauru' },
    { code: 'nv', name: 'Navajo, Navaho' },
    { code: 'nd', name: 'Northern Ndebele' },
    { code: 'ng', name: 'Ndonga' },
    { code: 'ne', name: 'Nepali' },
    { code: 'se', name: 'Northern Sami' },
    { code: 'no', name: 'Norwegian' },
    { code: 'nb', name: 'Norwegian Bokmål' },
    { code: 'nn', name: 'Norwegian Nynorsk' },
    { code: 'ii', name: 'Nuosu, Sichuan Yi' },
    { code: 'oc', name: 'Occitan (post 1500)' },
    { code: 'oj', name: 'Ojibwa' },
    { code: 'or', name: 'Oriya' },
    { code: 'om', name: 'Oromo' },
    { code: 'os', name: 'Ossetian, Ossetic' },
    { code: 'pi', name: 'Pali' },
    { code: 'pa', name: 'Panjabi, Punjabi' },
    { code: 'ps', name: 'Pashto, Pushto' },
    { code: 'fa', name: 'Persian' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'qu', name: 'Quechua' },
    { code: 'rm', name: 'Romansh' },
    { code: 'rn', name: 'Rundi' },
    { code: 'ru', name: 'Russian' },
    { code: 'sm', name: 'Samoan' },
    { code: 'sg', name: 'Sango' },
    { code: 'sa', name: 'Sanskrit' },
    { code: 'sc', name: 'Sardinian' },
    { code: 'sr', name: 'Serbian' },
    { code: 'sn', name: 'Shona' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'si', name: 'Sinhala, Sinhalese' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'st', name: 'Sotho, Southern' },
    { code: 'nr', name: 'South Ndebele' },
    { code: 'es', name: 'Spanish, Castilian' },
    { code: 'su', name: 'Sundanese' },
    { code: 'sw', name: 'Swahili' },
    { code: 'ss', name: 'Swati' },
    { code: 'sv', name: 'Swedish' },
    { code: 'tl', name: 'Tagalog' },
    { code: 'ty', name: 'Tahitian' },
    { code: 'tg', name: 'Tajik' },
    { code: 'ta', name: 'Tamil' },
    { code: 'tt', name: 'Tatar' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'bo', name: 'Tibetan' },
    { code: 'ti', name: 'Tigrinya' },
    { code: 'to', name: 'Tonga (Tonga Islands)' },
    { code: 'ts', name: 'Tsonga' },
    { code: 'tn', name: 'Tswana' },
    { code: 'tr', name: 'Turkish' },
    { code: 'tk', name: 'Turkmen' },
    { code: 'tw', name: 'Twi' },
    { code: 'ug', name: 'Uighur, Uyghur' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'uz', name: 'Uzbek' },
    { code: 've', name: 'Venda' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'vo', name: 'Volap_k' },
    { code: 'wa', name: 'Walloon' },
    { code: 'cy', name: 'Welsh' },
    { code: 'fy', name: 'Western Frisian' },
    { code: 'wo', name: 'Wolof' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'yi', name: 'Yiddish' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'za', name: 'Zhuang, Chuang' },
    { code: 'zu', name: 'Zulu' }
];
//#endregion

//#region Distinct Functions
export const log = (a, b) => {
    return
    console.log(a, b !== undefined ? b : '');
}
export const countItems = (number, single, plurial = '') => {
    return number + ' ' + (number === 1 ? single : plurial !== '' ? plurial : single + 's')
}
export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
export const isValideEmail = (mail) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return (true)
    }
    return (false)
}
export const getUserType = (accountType) => {
    if (accountType < 5) { return userType[accountType] } else { return 'Not Defined' }
}
export const getUserTypeID = (accountType) => {
    let i = 0, u = -1;
    userType.map(value => { if (value === accountType) { u = i } i += 1 })
    return u
}
export const getHotspotType = (hotspotType) => {
    if (hotspotType < hotspotTypes.length - 1) { return hotspotTypes[hotspotType] } else { return 'Not Defined' }
}
export const getHotspotTypeID = (hotspotType) => {
    let i = 0, u = -1;
    hotspotTypes.map(value => { if (value === hotspotType) { u = i } i += 1 })
    return u
}
export const getLanguageList = (language) => {
    var langArray = []
    language_list.map(element => { langArray.push(element.code) })
    return langArray
}

export const checkUserAccess = async () => {

    const token = getUserToken();

    if (token === "") { return [] }
    const result = await getData("CheckAccess", [])

    if (result === undefined) return [];
    if (result.status !== 200) return [];
    return await result.data;
}

export const getUserToken = () => {
    let Token = '';
    const userData = JSON.parse(sessionStorage.getItem(sessionStorageVariables.userData))

    if (userData == null) return Token;
    if (userData.token == null) return Token;
    return userData.token
}
export const getDistinctRecords = (Records, columnName, sort = false) => {
    let array = []
    Records.map(element => array.push(element[columnName]))
    let unikevalues = [...new Set(array)];

    if (sort) unikevalues.sort();
    return unikevalues

}
///loop over object Object.keys(obj).forEach(function(key) {console.log(key, obj[key]);});
//#endregion

//#region getData 

export async function getData(servicename, params, logs = false) {
    var bodies = ""
    for (var i = 0; i < params.length; i++) {
        bodies += '&' + params[i][0] + '=' + (params[i][1] === "" ? "" : params[i][1])

    }
    if (bodies.length > 2) bodies = bodies.substring(1);
    if (logs) log('Bodies', bodies);
    const link = nodeServerSite + servicename + "/"
    if (logs) log('link', link);
    if (logs) log('full path', link + '?' + bodies);
    let Token = getUserToken();
    let response = await fetch(link, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'authorization': Token

        },

        body: bodies
    })

    let data = response.json()
    let status = response.status;
    if (logs) log('Data', data);

    return { data, status };
}


//#endregion 


//#region RenderComponent
export const renderTextField = (name, type, placeHolder, onChange, valueField, errorArray, note = '', addClass = '', disabled = false, language=0, showborders=true) => {
    var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group className={'fullWidth ' + addClass}>
            <Form.Label htmlFor={key} >{ts(name,language)}{errorArray[key] && <span className='warningColor bold'> *</span>}</Form.Label><Badge className='ml-2' variant="info">{note}</Badge>
            <Form.Control disabled={disabled} className={(showborders?'':'border-top-0 border-right-0 border-left-0 border-light rounded-5') + ' p-1 '} style={errorArray[key] ? { borderColor: errorColor } : {}} name={key} type={type} placeholder={ts(placeHolder, language)} onChange={onChange} value={valueField[key] || ''} />
            {errorArray[key] && <div className='remarks notValidColor'>{errorArray[key]}</div>}
        </Form.Group>
    )
}
export const renderDropDown = (name, values, onChange, valueField, errorArray, defaultValue = '', disabled = false, language=0) => {
    var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group className='fullWidth '>
            <Form.Label htmlFor={key} >{ts(name,language)}{errorArray[key] && <span className='warningColor bold'> *</span>}</Form.Label>
            <select className='border border-light custom-select' disabled={disabled} data-value="fr" onfocus="this.setAttribute('data-value', this.value);" style={errorArray[key] ? { borderColor: errorColor } : {}} name={key} onChange={onChange} value={valueField[key] || ''}>
            
            {values.map(value => {
                    if(defaultValue == value)
                    {
                        return <option  selected>{value}</option>
                    }
                    else{
                        return <option  >{value}</option>
                    }
                      })}
            </select>
            {errorArray[key] && <div className='remarks notValidColor'>{errorArray[key]}</div>}
        </Form.Group>
    )
}
export const renderHotspotDropDown = (name, values, onChange, valueField, errorArray, defaultValue = '', disabled = false, language=0) => {
    var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group className='fullWidth '>
            <Form.Label htmlFor={key} >{ts(name,language)}{errorArray[key] && <span className='warningColor bold'> *</span>}</Form.Label>
            <select className='custom-select' disabled={disabled} data-value="fr" onfocus="this.setAttribute('data-value', this.value);" style={errorArray[key] ? { borderColor: errorColor } : {}} name={key} onChange={onChange} value={valueField[key] || ''}>
            <option></option>
            {values.map(value => {
                    if(defaultValue == value)
                    {
                        return <option  selected>{value}</option>
                    }
                    else{
                        return <option  >{value}</option>
                    }
                      })}
            </select>
            {errorArray[key] && <div className='remarks notValidColor'>{errorArray[key]}</div>}
        </Form.Group>
    )
}
export const renderDropDownWithId = (name,key, values, onChange, valueField, errorArray, defaultValue = '', disabled = false, language=0) => {
    //var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group className='fullWidth '>
            <Form.Label htmlFor={key} >{ts(name,language)}{errorArray[key] && <span className='warningColor bold'> *</span>}</Form.Label>
            <select className='border border-light custom-select' disabled={disabled}  style={errorArray[key] ? { borderColor: errorColor } : {}} name={key} onChange={onChange} value={valueField[key] || ''}>
                {defaultValue === '' ? '' : <option>{defaultValue}</option>}
                {values.map(value => { return <option value={value.key}>{value.value}</option> })}
            </select>
            {errorArray[key] && <div className='remarks notValidColor'>{errorArray[key]}</div>}
        </Form.Group>
    )
}
export const renderDatePicker = (name, selectedDate, onChange, errorArray, placeholder, dateFormat = "dd MMMM yyyy", showtime = false, minDate = null, language=0) => {
    var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group //style={{borderColor:'lightGray', borderStyle:'solid', borderWidth:0.2, borderRadius:3}} 
            className='fullWidth '>
            <div><Form.Label htmlFor={key} >{ts(name, language)}</Form.Label></div>
            <DatePicker minDate={minDate} showTimeSelect={showtime} className={' boxLightGray fullWidth ' + (errorArray[key] ? ' warningBorder ' : '')} selected={selectedDate} onChange={onChange} dateFormat={dateFormat}
                isClearable placeholderText={placeholder}
            >
                <div style={{ color: "lightgray", textAlign: 'center' }}>{placeholder}</div>
            </DatePicker>
            {errorArray[key] && <div className='remarks notValidColor'>{errorArray[key]}</div>}
        </Form.Group>
    )
}
export const renderMultiSelectionBox = (name, Array, placeHolder, closeAction, AutoComplete, addClass, language=0) => {
    var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group className={'fullWidth ' + addClass}>
            <Form.Label htmlFor={key} >{ts(name,language)}</Form.Label>

            <div className=''>
                <div className=''>{AutoComplete}</div>
                <div className=' fullWidth  flexrow wrap'>  
                    {Array.length === 0 && <span className='themeColor mr-2 mt-2 pl-2 outlineBorderButton pr-2'>{Array.length === 0 ? placeHolder : ""}</span>}
                    {Array && Array.map(element => {
                        return (
                            <span className='outlineBorderButton remarks mr-2 pl-2 pr-2 mr-2 mt-2 pb-1 boxRadius5 '>
                                {element}
                                <span className='ml-2 handCursor remarks ' onClick={() => { closeAction(element) }}>x</span>
                            </span>)
                    })}
                </div>
            </div>
        </Form.Group>
    )
}
export const renderAgeFilter = (name, Array, placeHolder, closeAction) => {
    var key = name.replace(" ", "").toLowerCase();
    return (
        <Form.Group className='fullWidth handCursor'>
            <Form.Label htmlFor={key} >{name}</Form.Label>
            <div className=' fullWidth boxLightGray p-2'>
                <span className='themeColor'>{Array.length === 0 ? placeHolder : ""}</span>

                {Array.map(element => {
                    return (
                        <span className='whiteColor ctheme mr-2 p-1 pl-2 pr-2 boxRadius5'>
                            {element}
                            <span className='ml-3 handCursor remarks ' onClick={() => { closeAction(element) }}>x</span>
                        </span>)
                })}
            </div>
        </Form.Group>
    )
}
export const renderPageContainer = (contents) => {
    return (
        <div className='pt-5 fluid' >
            {contents}
        </div>)
}
export const renderPageHeader = (text) => {
    return (
        <h4 className='mb-4 mt-4'> {text}</h4>
    )
}
export const renderDivider = () => {
    return (
        <div className='mb-5 mt-5 mr-5 ml-5 ' style={{ backgroundColor: '#eeeeee', height: 2, borderRadius: 1 }}></div>
    )
}



//#endregion


//#region Import & Export
export const downloadFile = (contents, name, format = 'txt') => {
    let output;
    if (format === "json") {
        output = JSON.stringify(contents)
    }
    else if (format === "csv") {
        // // Prepare data:
        // let contents = [];
        // contents.push(["State", "Electors"]);
        // this.state.data.forEach(row => {
        //     contents.push([row.state, row.electors])
        // });
        // output = this.makeCSV(contents);
    } else {

        output = contents;

    }
    // Download it
    const blob = new Blob([output]);
    const fileDownloadUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute("download", `${name}.${format}`)
    link.href = fileDownloadUrl;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.


}
export const makeCSV = (content) => {
    let csv = '';
    content.forEach(value => {
        value.forEach((item, i) => {
            let innerValue = item === null ? '' : item.toString();
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0) {
                result = '"' + result + '"'
            }
            if (i > 0) { csv += ',' }
            csv += result;
        })
        csv += '\n';
    })
    return csv
}
//#endregion